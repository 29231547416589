import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LetsTalk from "../../Assets/Images/LetsTalk.jpg";
import { Tabtitle } from '../../util/generalFunction';
import carbon_footprint_1 from "../../Assets/Images/News/Carbon_footprint-1.png"
import carbon_footprint_2 from "../../Assets/Images/News/Carbon_footprint-2.png"
import carbon_footprint_3 from "../../Assets/Images/News/Carbon_footprint-3.png"



const CarbonFootprnt = () => {
    const { t } = useTranslation("nxWorld")
    Tabtitle('Evercomm - Post')

    const [height, setHeight] = useState('')

    useEffect(() => {
        window.addEventListener('resize', function () {
            setHeight(document.getElementsById('work_plan_photo')[0]?.clientHeight)
        });
    }, [])

    useEffect(() => {
        setHeight(document.getElementsByClassName('work_plan_photo')[0]?.clientHeight)
    }, [])

    return (
        <>
            <div className="d-flex justify-content-center" style={{ flexWrap: "wrap" }}>
                <div className="col-lg-8 col-md-8 col-sm-10 col-10 mb-5">
                    <div className="">
                        <div className="d-flex justify-content-center" style={{ color: "#fff" }}>
                            <h5 className="my-lg-5 my-md-3 my-sm-2 my-2 px-lg-3 mb-0 px-md-3 px-sm-2 px-2 py-1 posts_tag " style={{ border: "1px solid #fff", backgroundColor: '#003f9c', borderRadius: 30, fontSize: '1rem' }}>Event</h5>
                        </div>
                    </div>

                    <div className="">
                        <div className="d-flex justify-content-center">
                            <div className="col-12 " style={{ textAlign: 'center', color: '#003f9c' }}>
                                <h1 className="title m-0 fw-bold" style={{ color: '#003f9c', fontSize: "3rem" }}>Carbon Footprint: Business Opportunity for the Future</h1>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-around  mt-lg-5 mt-md-5 mt-sm-3 mt-3" style={{ flexWrap: "wrap", textAlign: "start", color: '#003f9c' }}>

                        <div className="mb-lg-0 mb-md-0 mb-sm-3 mb-3 d-flex justify-content-center" style={{ flexWrap: "wrap" }}>
                            <img className="mb-lg-5 mb-md-3 mb-sm-2 mb-2 mx-auto" src={carbon_footprint_1} style={{ objectFit: "cover", width: "100%" }} />
                            <div style={{ backgroundColor: "#ffff" }}>
                                <p className="texts" style={{ fontSize: 20 }}>On March 21, 2024, Evercomm Thailand partnered with the SME Federation Samutprakarn province, Samutprakarn Industrial Federation, and Samutprakarn Environmental Federation to host a seminar titled “Carbon Footprint: Business Opportunity for the Future." Held at the Bang Pu Subdistrict Municipality Office, the seminar aimed to equip the companies in Samutprakarn province with essential knowledge to navigate towards net zero goals.</p>
                                <p className="texts" style={{ fontSize: 20 }}>Our CEO, Ted Chen, took the stage as a keynote speaker, highlighting innovative carbon reduction engineering solutions pivotal for transitioning industries towards a lower-carbon future. Alongside Ted, we were honoured to welcome distinguished speakers such as Mr. Amnuai Suvannaruk, Mr. Theerapol Srimora, Ms. Kulchuda Ditsayabutr, and Dr. Thanapong Duangmanee, who shared invaluable insights into sustainability and environmental stewardship.</p>
                            </div>
                            <img className="mb-lg-5 mb-md-3 mb-sm-2 mb-2" src={carbon_footprint_2} style={{ objectFit: "cover", width: "100%" }} />

                        </div>


                        <div className="" style={{}}>
                            <div className="d-flex justify-content-center" style={{ backgroundColor: "#ffff", flexWrap: "wrap" }}>
                                <p className="texts" style={{ fontSize: 20 }}>A significant milestone was marked with the signing ceremony between Samutprakarn Environmental Federation, Samutprakarn Industrial Federation, and Evercomm Data Services (Thailand) Co., Ltd. This momentous occasion reinforced Evercomm's commitment in extending NXMap to 300 companies in Samutprakarn province. NXMap will serve as a powerful tool for reporting and reviewing carbon emission levels, catalysing their journey towards carbon reduction.</p>
                                <img className="mb-lg-5 mb-md-3 mb-sm-2 mb-2" src={carbon_footprint_3} style={{ objectFit: "cover", width: "100%" }} />
                                <p className="texts" style={{ fontSize: 20 }}>In a panel discussion, KK Tan, our Business Development Manager (Malaysia), joined esteemed practitioners and experts from Bureau Veritas Thailand, Ms. Kulchuda Ditsayabutr and Mae Far Lung Foundation Thailand, Dr. Thanapong Duangmanee to delve deeper into carbon footprint and carbon credit. The session was moderated by Saichon, our Business Development Manager (Thailand), ensuring an enriching exchange of experiences and insights.</p>
                                <span>
                                    <p className="texts mb-0" style={{ fontSize: 20 }}>At Evercomm, we believe in driving meaningful change through collaboration and innovation. Speak to us to find out more about our suite of carbon reduction engineering solutions - </p>
                                    <a className="texts" style={{ color: "#0ebbdd", fontSize: 20 }} href="/contact">https://www.evercomm.com.sg/contact.</a>
                                </span>
                            </div>
                        </div>


                    </div>
                </div>
                <div className="row col-12 m-0 p-0 g-0 d-flex justify-content-center res-bg g-0" style={{ minHeight: '100vh' }}>
                        <div className='m-0' style={{ backgroundImage: `url(${LetsTalk})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '75% 50%', position: 'relative', minHeight: '100%' }}>
                            <div className="h-100 d-flex align-items-center mx-lg-5 mx-md-5 mx-sm-2 mx-2" style={{ position: 'absolute', top: 0, left: 0 }}>
                                <div className="col-12 px-lg-5 px-md-3 px-sm-2 px-2" style={{ textAlign: 'start', color: '#ffff' }}>
                                    <h2 className="header" style={{ textAlign: 'start', color: '#ffff', width: '100%', fontSize: "3rem" }}>{t("Let’s Journey Together")}</h2>
                                    <button className=" texts button" id=" " style={{ color: '#003f9c', outLine: 'none', border: '1px solid #ffff', width: 420, height: 45, fontSize: 18, marginTop: 12, backgroundColor: '#fff' }} onClick={() => window.location.assign('/contact#contact')}>{t("Let’s Talk")}</button>
                                </div>
                            </div></div>
                    </div>
            </div>
        </>
    )
}
export default CarbonFootprnt;