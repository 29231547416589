import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LetsTalk from "../../Assets/Images/LetsTalk.jpg";
import photo1 from "../../Assets/Images/News/BusinessWorkshop-2.jpg";
import photo2 from "../../Assets/Images/News/BusinessWorkshop-3.jpg";
import photo3 from "../../Assets/Images/News/BusinessWorkshop-4.jpg";
import photo4 from "../../Assets/Images/News/BusinessWorkshop-5.jpg";
import photo5 from "../../Assets/Images/News/BusinessWorkshop-6.jpg";
import photo6 from "../../Assets/Images/News/BusinessWorkshop-7.jpg";


import { Tabtitle } from '../../util/generalFunction';
import WorkshopBanner from "../../Assets/Images/News/BusinessWorkShop-1.jpg"


const BusinessWorkshop = () => {
    const { t } = useTranslation("nxWorld")
    Tabtitle('Evercomm - Post')

    const [height, setHeight] = useState('')

    useEffect(() => {
        setTimeout(function () {
            setHeight(document.getElementsByClassName('photo1')[0]?.clientHeight)
          }, 1000)
    }, [])

    useEffect(() => {
        window.addEventListener('resize', function () {
            setHeight(document.getElementsByClassName('photo1')[0]?.clientHeight)
        });
    }, [])

   
    console.log(height);

    return (
        <>
                <div className="d-flex justify-content-center" style={{ flexWrap: "wrap" }}>

                    <div className="col-lg-8 col-md-8 col-sm-10 col-10 mb-5">
                        <div style={{ backgroundColor: "#ffff" }}>

                            <div className="">
                                <div className="d-flex justify-content-center" style={{ color: "#fff" }}>
                                    <h5 className="my-lg-5 my-md-3 my-sm-2 my-2 px-lg-3 mb-0 px-md-3 px-sm-2 px-2 py-1 posts_tag " style={{ border: "1px solid #fff", backgroundColor: '#003f9c', borderRadius: 30, fontSize: '1rem' }}>Events</h5>
                                </div>
                            </div>

                            <div className="">
                                <div className="d-flex justify-content-center">
                                    <div className="col-12 mb-lg-5 mb-md-3 mb-sm-2 mb-2" style={{ textAlign: 'center', color: '#003f9c' }}>
                                    <p className="texts m-0" style={{fontSize: 20}}>Sustainable Business Workshop:</p>
                                      
                                        <h1 className="title m-0 fw-bold" style={{ color: '#003f9c', fontSize: "3rem" }}>Navigating Climate Policies and Carbon Reporting and Management for Future Success</h1>
                                    </div>
                                </div>
                            </div>



                            <div className="">
                                <div className="d-flex justify-content-center my-lg-5 my-md-3 my-sm-2 my-2">
                                    <div className="col-12 " style={{ textAlign: 'start', color: '#003f9c', display: 'grid' }}>
                                    <img className="mb-lg-5 mb-md-3 mb-sm-2 mb-2" src={WorkshopBanner} style={{objectFit: "cover", width: "100%"}}></img> 

                                        <p className="texts px-xl-4 px-lg-2 px-md-2 px-sm-2 px-2" style={{ fontSize: 20 }}>Together with our partners, <a style={{ color: '#00c8d6', textDecoration: 'none'}} href="https://www.sginnovate.com/" target={'_blank'}>SGInnovate</a> and <a style={{ color: '#00c8d6', textDecoration: 'none'}} href="https://www.bcorpsingapore.org/" target={'_blank'}>B Lab Singapore</a>, Evercomm hosted a Sustainable Business Workshop titled "Navigating Climate Policies and Carbon Reporting and Management for Future Success." It was a privilege to bring together industry leaders and business owners to delve into crucial topics shaping the sustainable business landscape in Singapore.</p>
                                        <p className="texts px-xl-4 px-lg-2 px-md-2 px-sm-2 px-2" style={{ fontSize: 20 }}>We were honoured to have <span className="fw-bold"> Cecilia Hough, Lead of Impact Measurement at B Lab Singapore</span>, and <span className="fw-bold">Jerry Lui, Assistant Director of Partnerships at SGinnovate</span>, to kick off the event and set the tone for an engaging discussion.</p>
                                        <p className="texts px-xl-4 px-lg-2 px-md-2 px-sm-2 px-2" style={{ fontSize: 20 }}>Our CEO, <span className="fw-bold">Chiu-Hao Chen (Ted)</span>, took centre stage as the main speaker, delivering key insights into navigating global climate disclosure policiels. From understanding the EU climate mandate to analysing Singapore's climate policy impact on local businesses, the workshop explored a spectrum of vital themes.</p>
                                        <p className="texts px-xl-4 px-lg-2 px-md-2 px-sm-2 px-2" style={{ fontSize: 20 }}>Participants gained invaluable knowledge on sustainability reporting frameworks, GHG reporting frameworks, and real-world case studies illustrating sustainable practices in business. The workshop aimed not only to educate but also to inspire action towards a more sustainable future.</p>
                                        <p className="texts px-xl-4 px-lg-2 px-md-2 px-sm-2 px-2" style={{ fontSize: 20 }}><span className="fw-bold">Rob Field-Marsham, our Project Manager for Net Zero Planning & Execution</span>, skilfully facilitated the workshop, ensuring a seamless flow of discussions and interactions. There was a robust exchange of ideas during the Q&A session, where attendees actively participated, contributing to the collective learning experience.</p>
                                        <p className="texts px-xl-4 px-lg-2 px-md-2 px-sm-2 px-2 m-0" style={{ fontSize: 20 }}>Speak to us to find out more about our suite of carbon reduction engineering solutions - </p>
                                        <a className="texts px-xl-4 px-lg-2 px-md-2 px-sm-2 px-2" href="mailto:info@evercomm.com.sg" style={{width: "fit-content", fontSize: 20 }}>info@evercomm.com.sg.</a>
                                    </div>
                                </div>
                            </div>

                            <div id="WorkshopCarousel" className="carousel slide" data-bs-ride="false">
                                <div className="carousel-indicators">
                                    <button type="button" data-bs-target="#WorkshopCarousel" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#WorkshopCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#WorkshopCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                    <button type="button" data-bs-target="#WorkshopCarousel" data-bs-slide-to="3" aria-label="Slide 4"></button>
                                    <button type="button" data-bs-target="#WorkshopCarousel" data-bs-slide-to="4" aria-label="Slide 5"></button>
                                    <button type="button" data-bs-target="#WorkshopCarousel" data-bs-slide-to="5" aria-label="Slide 6"></button>

                                </div>
                                <div className="carousel-inner">
                                    <div className="carousel-item active photo1">
                                        <img src={photo1} className="d-block w-100" alt="..." />
                                    </div>
                                    {
                                        WorkshopImgs.map((v, k) => {
                                            return <div className="carousel-item " key={k} >
                                                <img src={v} className="d-block w-100" style={{objectFit:"cover", height: height}} alt="..." />
                                            </div>
                                        })
                                    }
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#WorkshopCarousel" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#WorkshopCarousel" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>


                        </div>
                    </div>
                    <div className="row col-12 m-0 p-0 g-0 d-flex justify-content-center res-bg g-0" style={{ minHeight: '100vh' }}>
                    <div className='m-0' style={{ backgroundImage: `url(${LetsTalk})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: '75% 50%', position: 'relative', minHeight: '100%' }}>
                        <div className="h-100 d-flex align-items-center mx-lg-5 mx-md-5 mx-sm-2 mx-2" style={{ position: 'absolute', top: 0, left: 0 }}>
                            <div className="col-12 px-lg-5 px-md-3 px-sm-2 px-2" style={{ textAlign: 'start', color: '#ffff' }}>
                                <h2 className="header" style={{ textAlign: 'start', color: '#ffff', width: '100%', fontSize: "3rem" }}>{t("Let’s Journey Together")}</h2>
                                <button className=" texts button" id=" " style={{ color: '#003f9c', outLine: 'none', border: '1px solid #ffff', width: 420, height: 45, fontSize: 18, marginTop: 12, backgroundColor: '#fff' }} onClick={() => window.location.assign('/contact#contact')}>{t("Let’s Talk")}</button>
                            </div>
                        </div></div>
                </div>
            </div>
        </>
    )
}
export default BusinessWorkshop;
const WorkshopImgs = [
     photo2, photo3, photo4, photo5, photo6]