import React, { useEffect, useState } from "react";
import { acceptConsent,declineConsent } from "../../analytic";
const ModalBox = () => {
   

    return (
//       <div>
//         <button type="button" class="btn btn-primary modalBoxBtn" style={{display:'none'}} data-toggle="modal" data-target="#exampleModalCenter">
//   Modal Box
// </button>
//         {/* {
//           showModal && ( */}
// <div class="modal fade show" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" data-backdrop="static"  aria-hidden="false">
//     <div class="modal-dialog modal-dialog-centered" role="document">
//       <div class="modal-content">
//         <div class="modal-body">
//               <div style={{color:'#002c96',fontWeight:'bold',fontSize:'18px',marginBottom:'15px'}}>We use cookies</div>
//               <div style={{marginBottom:'15px'}}>
//               Our website uses cookies to enhance your browsing experience, provide personalised content, and analyse site traffic. By continuing, you consent to our use of cookies as described in <a href="https://www.evercomm.com.sg/privacy">our Privacy Policy</a>. 
//               </div>
//               <div style={{display:'flex',gap:'10px',justifyContent:'center',alignItems:'center'}}>
//               {/* <div style={{padding}}>Accept all</div> */}
//               <button className="btn"
//               data-dismiss="modal" aria-label="Close" 
//               style={{backgroundColor:'#002c96',borderRadius:'25px',color:'white'}}
//               onClick={acceptConsent}
//               >Accept all</button>
//               <button className="btn" style={{backgroundColor:'#002c96',borderRadius:'25px',color:'white'}}
//               data-dismiss="modal" aria-label="Close"
//               onClick={declineConsent}
//               >Use strictly necessary only</button>
//               </div>
//               {/* <div>Use strictly necessary only</div> */}

//         </div>
//       </div>
//     </div>
//           </div>  
//           {/* )
//         } */}
          
//       </div>
      <div className="cookie-consent" id="consent" style={{display: "none"}}>

        <div>
          <h1 style={{"fontWeight": "bold", "fontSize": "20px"}}>We use cookies</h1>
          <div style={{marginBottom:'15px'}}>
              Our website uses cookies to enhance your browsing experience, provide personalised content, and analyse site traffic. By continuing, you consent to our use of cookies as described in our <a href="https://www.evercomm.com.sg/privacy">Privacy Policy</a>. 
               </div>
          <button className="consent-accept me-3 mb-lg-0 mb-md-0 mb-sm-2 mb-2" onClick={acceptConsent}>Accept all</button>
          <button className="consent-decline"  onClick={declineConsent}>Use strictly necessary only</button>

        </div>
      </div>
    )

};
export default ModalBox;
