import React, { useEffect, useState } from "react";



const Terms = () => {
    const [height, setHeight] = useState('')

    useEffect(() => {
        window.addEventListener('resize', function () {
            setHeight(document.getElementsByClassName('navbar')[0]?.clientHeight)
        });
    }, [])

    useEffect(() => {
        setHeight(document.getElementsByClassName('navbar')[0]?.clientHeight)
    }, [])
    return (
        <div className='d-flex justify-content-center' style={{ marginTop: `${height}px` }}>
            <div className='col-lg-7 col-md-8 col-sm-10 col-12 px-5 py-5' style={{ textAlign: "start" }}>
                <h2 className="header" style={{ fontSize: '3rem' }}>TERMS OF USE</h2>
                <h5 className="subtitle" style={{ fontSize: '1.7rem' }}>Last Updated: 29 April 2024</h5>
                <p className="texts" style={{ fontSize: 20 }}>By continuing to browse and use this website (<span style={{ color: "#003f9c", cursor: "pointer" }} onClick={() => window.open("http://www.evercomm.com.sg", '_blank')}>www.evercomm.com.sg</span>), the user (“you”, “your”) agrees to comply with and be bound by these Terms of Use (“Terms”).</p>
                <h className="texts fw-bold" style={{ fontSize: 20 }}>Use of Website</h>

                <p className="texts" style={{ fontSize: 20 }}>This website is owned by Evercomm Singapore Pte. Ltd. (“Evercomm”, “we”, “our”) (UEN: 201321526K). You may use our website for lawful purposes only.</p>
                <p className="texts" style={{ fontSize: 20 }}>In no event shall this website be used for any unlawful, fraudulent, or libellous purposes. This includes transmitting any unsolicited or unauthorised advertisements or promotional material; knowingly transmitting or uploading any malicious code; attempting to collect or store personal data about third parties without their knowledge or consent; and damaging, interfering with, accessing without authority, or disrupting any part of the website or the network on which it is hosted.</p>
                <p className="texts" style={{ fontSize: 20 }}>Evercomm shall maintain sole discretion to decide whether an action constitutes a violation of the above restrictions, and reserves the right to undertake legal action against any such violations.</p>
                <h className="texts fw-bold" style={{ fontSize: 20 }}>Intellectual Property</h>
                <p className="texts" style={{ fontSize: 20 }}>This website, its content and its text, graphics, logos, images, audio clips, and software (collectively “Properties”), are owned or licensed by Evercomm and are protected by copyright, trademark, and other intellectual property laws. You may not use, reproduce, modify, or distribute any content from our website without our prior written consent from Evercomm. Any references made to the contents of this website should be done via an attribution link.</p>
                <h className="texts fw-bold" style={{ fontSize: 20 }}>Use of Cookies</h>
                <p className="texts" style={{ fontSize: 20 }}>This website makes use of cookies to monitor browsing preferences. Cookies are text files containing information relating to the user’s browsing preferences and are stored on the user’s device. Evercomm does not use the data collected to personally identify any user. If you do not wish to accept cookies from this website, you may disable cookies in your browser.</p>
                <h className="texts fw-bold" style={{ fontSize: 20 }}>Disclaimer of Warranties</h>
                <p className="texts" style={{ fontSize: 20 }}>Any use of this website and its contents is solely at the user’s own risk. While Evercomm has attempted to provide accurate information on this website, Evercomm does not provide any guarantees as to the accuracy of the information, which may contain technical inaccuracies, omissions or other typographical errors.</p>
                <p className="texts" style={{ fontSize: 20 }}>Evercomm makes no commitment to update the information provided on this website and will not be held responsible in the case where the information provided is inaccurate.</p>
                <h className="texts fw-bold" style={{ fontSize: 20 }}>Limitation of Liability</h>
                <p className="texts" style={{ fontSize: 20 }}>To the fullest extent permitted by law, we will not be liable for any direct, indirect, incidental, consequential, or punitive damages or losses arising out of or in connection with your use of this website, including but not limited to damages for lost profits, lost data, or business interruption.</p>
                <h className="texts fw-bold" style={{ fontSize: 20 }}>Indemnification</h>
                <p className="texts" style={{ fontSize: 20 }}>You agree to indemnify and hold harmless Evercomm and its officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in connection with your use of our website or your violation of these Terms.</p>
                <h className="texts fw-bold" style={{ fontSize: 20 }}>Links to Third-Party Websites</h>
                <p className="texts" style={{ fontSize: 20 }}>This website may contain links to third-party sites or services that are not owned, affiliated or related to Evercomm. Evercomm is not responsible for the information provided by these third-party sites and services, and shall not be liable, directly or indirectly, for any damages or losses suffered as the result of using the information. We encourage you to review the terms of use and privacy policies of any third-party websites that you visit.</p>
                <h className="texts fw-bold" style={{ fontSize: 20 }}>Changes to These Terms</h>
                <p className="texts" style={{ fontSize: 20 }}>Evercomm reserves the right to amend any part of these Terms without prior notice to reflect changes in our practices or legal requirements. You are advised to review these Terms on a regular basis to ensure that you understand and agree to the Terms. In the event where you are not agreeable to the Terms, kindly discontinue the use of this website.</p>
                <h className="texts fw-bold" style={{ fontSize: 20 }}>Governing Law</h>
                <p className="texts" style={{ fontSize: 20 }}>These Terms are governed by and construed in accordance with the laws of Singapore, without regard to its conflict of law principles.</p>
                <h className="texts fw-bold" style={{ fontSize: 20 }}>Contact Us</h>
                <p className="texts" style={{ fontSize: 20 }}>If you have any questions or concerns about these Terms, or to report a suspected violation of these Terms, kindly direct the information to: <span style={{ color: "#003f9c", cursor: "pointer" }} onClick={() => window.location.href = 'mailto:info@evercomm.com.sg'}>info@evercomm.com.sg</span></p>
            </div></div>
    )
}
export default Terms;