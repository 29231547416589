import React, { useEffect, useState } from "react";



const Privacy = () => {
    const [height, setHeight] = useState('')

    useEffect(() => {
        window.addEventListener('resize', function () {
            setHeight(document.getElementsByClassName('navbar')[0]?.clientHeight)
        });
    }, [])

    useEffect(() => {
        setHeight(document.getElementsByClassName('navbar')[0]?.clientHeight)
    }, [])
    return (
        <div className='d-flex justify-content-center' style={{ marginTop: `${height}px` }}>
            <div className='col-lg-7 col-md-8 col-sm-10 col-12 px-5 py-5' style={{ textAlign: "start" }}>
                <h2 className="header" style={{ fontSize: '3rem' }}>PRIVACY POLICY</h2>
                <h5 className="subtitle" style={{ fontSize: '1.7rem' }}>Last Updated: 29 April 2024</h5>
                <h className="texts fw-bold" style={{ fontSize: 20 }}>1. Introduction</h>
                <p className="texts" style={{ fontSize: 20 }}>1.1. Evercomm and its subsidiaries which are established in Singapore (which include, without limitation, collectively, “Evercomm” or “we”, “us”, “our”, as the case may be) respect the privacy and confidentiality of the personal data of our customers, partners, consultants, contractors, service providers, outsourced third-parties and any other parties who have dealings with us. We are committed to implementing policies, practices and processes to safeguard the collection, use or disclosure of the personal data you provide us, in compliance with the Singapore Personal Data Protection Act 2012 (“PDPA”). For more information about the Singapore Personal Data Protection Act 2012, please visit the Personal Data Protection Commission’s website at <span style={{color: "#003f9c", cursor: "pointer"}} onClick={()=> window.open("http://www.pdpc.gov.sg",'_blank')}>http://www.pdpc.gov.sg.</span></p>
                <p className="texts" style={{ fontSize: 20 }}>1.2. We have developed this policy (“Privacy Policy”) to cover the information practices, and to explain how Evercomm collects, uses, discloses, processes and handles the personal data in our possession or under our control which you (referred hereinafter as “you“, “your” or “User“) may have provided in the course of the use of the website (“Site”) and its functions available or facilitated by the Site (the “Services”), or in connection with your dealings with us or that we receive through third parties.</p>
                <h className="texts fw-bold" style={{ fontSize: 20 }}>2. Defined Terms</h>
                <p className="texts m-0" style={{ fontSize: 20 }}>2.1.   As used in this Privacy Policy:</p>
                <span className="d-flex ps-5">
                    <p className="texts m-0" style={{ fontSize: 20, minWidth: 30 }}>a:</p>
                    <p className="texts m-0" style={{ fontSize: 20 }}>“personal data” is defined under the PDPA to mean data, whether true or not, about an individual who can be identified — (a) from that data; or (b) from that data and other information to which the organization has or is likely to have access.Depending on the circumstances or the nature of the transaction or dealing with you, the personal data we may collect from you includes, without limitation, your name, address, personal identification, telephone number, email address, records of communication with us, credit card or debit card information, camera or video footage that identifies individuals installed at some parts of our premises as part of our security infrastructure, and other identifiers when you register or use the Site;</p>
                </span>
                <span className="d-flex ps-5">
                    <p className="texts m-0" style={{ fontSize: 20, minWidth: 30 }}>b:</p>
                    <p className="texts m-0" style={{ fontSize: 20 }}>“data intermediary” means an organization which processes personal data on behalf of another organization but does not include an employee of that other organization; and</p>

                </span><span className="d-flex ps-5">
                    <p className="texts m-0" style={{ fontSize: 20, minWidth: 30 }}>c:</p>
                    <p className="texts m-0" style={{ fontSize: 20 }}>“processing” is defined under the PDPA, where in relation to personal data, means the carrying out of any operation or set of operations in relation to the personal data, and includes any of the following: (a) recording; (b) holding; (c) organization, adaptation or alteration; (d) retrieval; (e) combination; (f) transmission; (g) erasure or destruction.</p>

                </span>
                <p className="texts" style={{ fontSize: 20 }}>2.2. Except where a different meaning is set out, the terms in this Privacy Policy have the same meaning as in the PDPA.</p>
                <p className="texts" style={{ fontSize: 20 }}>2.3. This Privacy Policy may be amended or updated, at any time, from time to time, and the latest version will be posted on our website and take effect from the date and time of posting</p>
                <h className="texts fw-bold" style={{ fontSize: 20 }}>3. Purposes for which Personal Data is Collected, Used, Disclosed, Processed or Handled</h>
                <p className="texts m-0" style={{ fontSize: 20 }}>3.1. In respect of all personal data you provide to us (including on or through the Site or Services), you consent, in the manner set out under paragraph 4 below, to the collection, use, disclosure, processing or handling, by us or our affiliates (including any data intermediary of ours, related companies or affiliates) of such personal data, for one or more of the following purposes:</p>
                <span className="d-flex ps-5">
                    <p className="texts m-0" style={{ fontSize: 20, minWidth: 30 }}>a:</p>
                    <p className="texts m-0" style={{ fontSize: 20 }}>to provide our services;</p>
                </span>
                <span className="d-flex ps-5">
                    <p className="texts m-0" style={{ fontSize: 20, minWidth: 30 }}>b:</p>
                    <p className="texts m-0" style={{ fontSize: 20 }}>responding to your queries, requests and feedback;</p>

                </span><span className="d-flex ps-5">
                    <p className="texts m-0" style={{ fontSize: 20, minWidth: 30 }}>c:</p>
                    <p className="texts m-0" style={{ fontSize: 20 }}>to maintain your account with us and process payments or verify your identity / entitlement to use the Site or Services or any part thereof;</p>

                </span><span className="d-flex ps-5">
                    <p className="texts m-0" style={{ fontSize: 20, minWidth: 30 }}>d:</p>
                    <p className="texts m-0" style={{ fontSize: 20 }}>to execute the operation, fulfilment, and administration of part of the Services or functions or features of the Site;</p>

                </span><span className="d-flex ps-5">
                    <p className="texts m-0" style={{ fontSize: 20, minWidth: 30 }}>e:</p>
                    <p className="texts m-0" style={{ fontSize: 20 }}>for general management and reporting purposes, such as invoicing and account management;</p>

                </span><span className="d-flex ps-5">
                    <p className="texts m-0" style={{ fontSize: 20, minWidth: 30 }}>f:</p>
                    <p className="texts m-0" style={{ fontSize: 20 }}>for recruitment purposes;</p>

                </span><span className="d-flex ps-5">
                    <p className="texts m-0" style={{ fontSize: 20, minWidth: 30 }}>g:</p>
                    <p className="texts m-0" style={{ fontSize: 20 }}>to facilitate the negotiation, entry and execution into various contracts with other third parties or make certain third-party services available to you;</p>

                </span><span className="d-flex ps-5">
                    <p className="texts m-0" style={{ fontSize: 20, minWidth: 30 }}>h:</p>
                    <p className="texts m-0" style={{ fontSize: 20 }}>to communicate with you to inform you of the services we offer, industry developments, service offerings, changes to the Site or Services, or our policies, terms and conditions and other administrative information and of any seminars and events that may be of interest to you;</p>

                </span><span className="d-flex ps-5">
                    <p className="texts m-0" style={{ fontSize: 20, minWidth: 30 }}>i:</p>
                    <p className="texts m-0" style={{ fontSize: 20 }}>to comply with our obligations owed to you in respect of the performance of our services to you or enforce our rights under (or in connection with) the same;</p>

                </span><span className="d-flex ps-5">
                    <p className="texts m-0" style={{ fontSize: 20, minWidth: 30 }}>j:</p>
                    <p className="texts m-0" style={{ fontSize: 20 }}>to comply with any applicable law, judgment, order, code, standard or regulation and participate in (or co-operate with) legal, regulatory or administrative proceedings or directions;</p>

                </span><span className="d-flex ps-5">
                    <p className="texts m-0" style={{ fontSize: 20, minWidth: 30 }}>k:</p>
                    <p className="texts m-0" style={{ fontSize: 20 }}>to protect and safeguard the operation of the Site and Services and performance of our services to you including preventing, detecting, reporting and supporting / assisting in investigations into any crime (including fraud, theft, money laundering, terrorism misuse or abuse of the Site or Services), cybersecurity incidences or risks, or any of the same where suspected;</p>

                </span><span className="d-flex ps-5">
                    <p className="texts m-0" style={{ fontSize: 20, minWidth: 30 }}>l:</p>
                    <p className="texts m-0" style={{ fontSize: 20 }}>to conduct or facilitate audits for the purposes of meeting legal requirements, compliance or industry standards, or as may be desirable to enhance the standing, quality, scope and capacity of the Site or Services;</p>

                </span><span className="d-flex ps-5">
                    <p className="texts m-0" style={{ fontSize: 20, minWidth: 30 }}>m:</p>
                    <p className="texts m-0" style={{ fontSize: 20 }}>to conduct data analytics, including (but not limited to) matching and profiling you to provide you with features and functions of the Site or the Services which may be relevant or of interest to you and to improve your user experience and for the purposes of enhancing the Site or Services or developing new product or service offerings; and</p>

                </span><span className="d-flex ps-5">
                    <p className="texts m-0" style={{ fontSize: 20, minWidth: 30 }}>n:</p>
                    <p className="texts" style={{ fontSize: 20 }}>such other purposes as may be reasonably related to the above or to our business</p>

                </span>
                <p className="texts" style={{ fontSize: 20 }}>For the purposes of paragraph 3.1(m) above, personal data will be aggregated and / or anonymized, such that no one individual can be identified or recognized from the user statistics in question. You acknowledge and agree that any anonymised data is not personal data and that we have the right to share and disclose such anonymized data with third parties for any purposes.</p>
                <p className="texts" style={{ fontSize: 20 }}>3.2. Without limiting paragraph 3.1, we may disclose your personal data to members within Evercomm where it is necessary:</p>
                <span className="d-flex ps-5">
                    <p className="texts m-0" style={{ fontSize: 20, minWidth: 30 }}>a:</p>
                    <p className="texts m-0" style={{ fontSize: 20 }}>to meet the purpose for which you had submitted the information; or</p>
                </span>
                <span className="d-flex ps-5">
                    <p className="texts m-0" style={{ fontSize: 20, minWidth: 30 }}>b:</p>
                    <p className="texts" style={{ fontSize: 20 }}>to third party service providers, agents and other organisations (whether located within or outside Singapore) we have engaged to perform any of the functions listed in paragraph 3.1 for us and for said third party to subsequently collect, use, disclose or process your personal data for the functions listed in paragraph 3.1.</p>
                </span>
                <span className="d-flex ps-5">
                    <p className="texts m-0" style={{ fontSize: 20, minWidth: 30 }}></p>
                    <p className="texts" style={{ fontSize: 20 }}>Any such third parties whom we engage for the purposes of this paragraph will be bound contractually to keep all information confidential.</p>
                </span>
                <h className="texts fw-bold" style={{ fontSize: 20 }}>4. Consent</h>
                <p className="texts" style={{ fontSize: 20 }}>4.1. Evercomm will collect, use or disclose personal data for the purposes listed in paragraph 3 above only if there is consent or deemed consent from the individual and information on such purposes have been notified.</p>
                <p className="texts" style={{ fontSize: 20 }}>4.2. The use of this Site or Services and performance of our services to you, is subject to the condition that and you hereby warrant and represent to us that any personal data has been provided for any purposes stated in the Privacy Policy is with, at all times, the full consent of the individual identified by the personal data (the “data subject”), such consent not having been revoked except where adequate and reasonable advance notice of the same has been given and duly acknowledged by us.</p>
                <p className="texts" style={{ fontSize: 20 }}>4.3. The consent that you provide for the collection, use or disclosure of your personal data will remain valid until such time it is being withdrawn by you in writing. You may withdraw your consent for the collection, use, or disclosure of your personal data for the purposes listed in paragraph 3 above at any time, by emailing us or our Data Protection Officer through the contact details provided below. We will process your request within a reasonable time from such a request for withdrawal of consent being made, and will thereafter not collect, use or disclose your personal data in the manner stated in your request, unless an exception under any applicable laws, judgment, order, code, standard or regulation, permits us to.</p>
                <p className="texts" style={{ fontSize: 20 }}>4.4. You agree and acknowledge that the withdrawal of any consents may impact the ability to proceed with or use any of the Site or Services including our ability to comply with any obligations owed to you in respect of the performance of our services to you or do anything in connection with our performance of our services to you. Such withdrawal of consent will be on the basis that you will release us from any obligations in connection with the same, and that you will indemnify us and hold us harmless from and against any claim, demand, loss, damage, cost, or liability (including reasonable legal fees) which we may suffer or suffers in connection with or arising from such withdrawal.</p>
                <h className="texts fw-bold" style={{ fontSize: 20 }}>5. Accuracy, Completeness, Access and Updating of Personal Data</h>
                <p className="texts" style={{ fontSize: 20 }}>5.1. The use of this Site or Services performance of our services to you, is subject to the condition that and you hereby warrant and represent to us that any personal data has been provided for any purposes stated in the Privacy Policy is, at all times, accurate, complete, and kept up to date by you at all times. We will make reasonable efforts to ensure that personal data provided to us is collected by us or on our behalf is accurate and complete.</p>
                <p className="texts" style={{ fontSize: 20 }}>5.2. If there are changes to your personal data or if you wish to correct an error or omission in your personal data that is in our possession or under our control, please update us by emailing us or our Data Protection Officer through the contact details provided below, and we will make such changes or correction in accordance with the requirements of the PDPA.</p>
                <p className="texts" style={{ fontSize: 20 }}>5.3. Upon request, we will provide you with access to your personal data or other appropriate information on your personal data that is in our possession or under our control, in accordance with the requirements of the PDPA.</p>
                <p className="texts" style={{ fontSize: 20 }}>5.4. Please note that we retain the right to charge for a request for access in accordance with the requirements of the PDPA.</p>
                <h className="texts fw-bold" style={{ fontSize: 20 }}>6. Your Obligations to Ensure Security & Confidentiality</h>
                <p className="texts" style={{ fontSize: 20 }}>6.1. You are solely responsible for maintaining the security and confidentiality of any personal data provided or made available by or through you and where any of the Site is installed or accessed via any device, you agree that you will safeguard the access to the use of the same on that device by applying security measures as may be reasonably appropriate.</p>
                <p className="texts" style={{ fontSize: 20 }}>6.2. Although we take reasonable steps to maintain the security and confidentiality of your personal data, you should be aware that no method of transmission over the internet or method of electronic storage is completely secure.</p>
                <h className="texts fw-bold" style={{ fontSize: 20 }}>7. Transfers of your Data / Data export</h>
                <p className="texts" style={{ fontSize: 20 }}>In submitting personal data to us, the personal data may be transferred / exported to locations in other territories outside of your location for the purposes of data processing (as defined above) by third party data intermediaries (as defined above). We endeavour to do so in each instance in accordance with applicable laws and require such data intermediaries to comply with all applicable laws and to provide an equivalent standard of protection for such personal data as such personal data would have received had the personal data remained in your territory. Such personal data may be stored in servers operated by third party service providers.</p>
                <h className="texts fw-bold" style={{ fontSize: 20 }}>8. Cookies / Log files</h>
                <p className="texts" style={{ fontSize: 20 }}>8.1. As with most other websites, we collect and use the data contained in log files. The information in the log files include your IP (internet protocol) address, your ISP (internet service provider), the browser you used to visit the Site, the time you visited the Site and which pages you visited throughout the Site. When you visit or interact with the Sites or Services, we or our authorized service providers may use cookies, web beacons, and other similar technologies for collecting and storing information to help provide you with a better, faster, and safer web experience. Note that:</p>
                <span className="d-flex ps-5">
                    <p className="texts m-0" style={{ fontSize: 20, minWidth: 30 }}>a:</p>
                    <p className="texts m-0" style={{ fontSize: 20 }}>A cookie is a small file containing a string of characters that is sent to your computer when you visit the Site for record keeping purposes. Cookies are stored in your browser’s file directory, and the next time you visit the Site, your browser will read the cookie and relay the information back to the Site or element that originally set the cookie.</p>
                </span>
                <span className="d-flex ps-5">
                    <p className="texts m-0" style={{ fontSize: 20, minWidth: 30 }}>b:</p>
                    <p className="texts m-0" style={{ fontSize: 20 }}>Depending on the type of cookie it is, cookies may store user preferences and other information, such as how a visitor arrives at the Site, what kind of browser a visitor is on, what operating system a visitor is using, a visitor’s IP address and a visitor’s click stream information and time stamp (for example, which pages they have viewed, the time the pages were accessed and the time spent per web page). There are several types of cookies, these include, without limitation: (i) session cookies expire at the end of your browser session and allow us to link your actions during that particular browser session; (ii) persistent cookies are stored on your device in between browser sessions, allowing us to remember your preferences or actions across multiple sites; (iii) first-party cookies are set by the Site; and (iv) third-party cookies are set by a third party site separate from the Site.</p>

                </span><span className="d-flex ps-5">
                    <p className="texts m-0" style={{ fontSize: 20, minWidth: 30 }}>c:</p>
                    <p className="texts m-0" style={{ fontSize: 20 }}>The use of cookies, web beacons and similar technologies by us on the Site have different functions. They are either necessary for the functioning of the Services, help us improve our performance, or serve to provide you with extra functionalities.</p>

                </span><span className="d-flex ps-5">
                    <p className="texts m-0" style={{ fontSize: 20, minWidth: 30 }}>d:</p>
                    <p className="texts m-0" style={{ fontSize: 20 }}>Cookies can be disabled or removed by tools that are available in most commercial browsers. The preferences for each browser you use will need to be set separately and different browsers offer different functionality and options. Deleting cookies does not mean you are permanently opted out of any advertising program. Unless you have settings that disallow cookies, the next time you visit a site running the advertisements, a new cookie will be added.</p>

                </span><span className="d-flex ps-5">
                    <p className="texts m-0" style={{ fontSize: 20, minWidth: 30 }}>e:</p>
                    <p className="texts m-0" style={{ fontSize: 20 }}>We offer certain site features and services that are available only through the use of these technologies. You are always free to block, delete, or disable these technologies if your browser so permits. However, if you decline cookies or other similar technologies, you may not be able to take advantage of certain site features or services tools. For more information on how you can block, delete, or disable these technologies, please review your browser settings.</p>

                </span><span className="d-flex ps-5">
                    <p className="texts m-0" style={{ fontSize: 20, minWidth: 30 }}>f:</p>
                    <p className="texts m-0" style={{ fontSize: 20 }}>What do we use cookies for?</p>
                </span>
                <span className="d-flex ps-5">
                    <span className="d-flex ps-5">
                        <p className="texts m-0" style={{ fontSize: 20, minWidth: 30 }}>I:</p>
                        <p className="texts m-0" style={{ fontSize: 20 }}>For the purposes set out above in the Privacy Policy;</p>
                    </span>
                </span>
                <span className="d-flex ps-5">
                    <span className="d-flex ps-5">
                        <p className="texts m-0" style={{ fontSize: 20, minWidth: 30 }}>II:</p>
                        <p className="texts m-0" style={{ fontSize: 20 }}>To help speed up your future browsing activities and boost your overall user experience on the Site or Services;</p>
                    </span>
                </span><span className="d-flex ps-5">
                    <span className="d-flex ps-5">
                        <p className="texts m-0" style={{ fontSize: 20, minWidth: 30 }}>III:</p>
                        <p className="texts m-0" style={{ fontSize: 20 }}>To enable the Site to operate, for example, cookies that enable you to log onto secure parts of the Site or use the Services;</p>
                    </span>
                </span><span className="d-flex ps-5">
                    <span className="d-flex ps-5">
                        <p className="texts m-0" style={{ fontSize: 20, minWidth: 30 }}>IV:</p>
                        <p className="texts m-0" style={{ fontSize: 20 }}>To track you as you navigate the Site and to enable the use of any features of the Site or Services (including, without limitation, e-commerce facilities); and</p>
                    </span>
                </span>
                <span className="d-flex ps-5">
                    <span className="d-flex ps-5">
                        <p className="texts m-0" style={{ fontSize: 20, minWidth: 30 }}>V:</p>
                        <p className="texts" style={{ fontSize: 20 }}>We use cookies to personalise the Site or Services for you.</p>
                    </span>
                </span>
                <h className="texts fw-bold" style={{ fontSize: 20 }}>9. European Union’s Data Subject rights</h>
                <p className="texts" style={{ fontSize: 20 }}>The European Union’s General Data Protection Regulation (GDPR) and other countries’ privacy laws provide certain rights for data subjects. Data Subject rights under GDPR include the following:</p>
                <span className="d-flex ps-5">
                    <ul>
                        <li className="texts" style={{ fontSize: 20 }}>Right to be informed</li>
                        <li className="texts" style={{ fontSize: 20 }}>Right of access</li>
                        <li className="texts" style={{ fontSize: 20 }}>Right to rectification</li>
                        <li className="texts" style={{ fontSize: 20 }}>Right to erasure</li>
                        <li className="texts" style={{ fontSize: 20 }}>Right to restrict processing</li>
                        <li className="texts" style={{ fontSize: 20 }}>Right of data portability</li>
                        <li className="texts" style={{ fontSize: 20 }}>Right to object</li>
                        <li className="texts" style={{ fontSize: 20 }}>Rights related to automated decision making including profiling</li>
                    </ul>
                </span>

                <p className="texts" style={{ fontSize: 20 }}>This Privacy Notice is intended to provide you with information about what personal data Evercomm collects about you and how it is used.</p>
                <p className="texts" style={{ fontSize: 20 }}>If you wish to confirm that Evercomm is processing your personal data, or to have access to the personal data Evercomm may have about you, please contact us.</p>
                <p className="texts" style={{ fontSize: 20 }}>You may also request information about: the purpose of the processing; the categories of personal data concerned; who else outside Evercomm might have received the data from Evercomm; what the source of the information was (if you did not provide it directly to Evercomm); and how long it will be stored. You have a right to correct (rectify) the record of your personal data maintained by Evercomm if it is inaccurate. You may request that Evercomm erase that data or cease processing it, subject to certain exceptions. You may also request that Evercomm cease using your data for direct marketing purposes. In many countries, you have a right to lodge a complaint with the appropriate data protection authority if you have concerns about how Evercomm processes your personal data. When technically feasible, Evercomm will—at your request—provide your personal data to you.</p>
                <p className="texts" style={{ fontSize: 20 }}>Reasonable access to your personal data will be provided at no cost. If access cannot be provided within a reasonable time frame, Evercomm will provide you with a date when the information will be provided. If for some reason access is denied, Evercomm will provide an explanation as to why access has been denied.</p>
                <p className="texts" style={{ fontSize: 20 }}>For questions or complaints concerning the processing of your personal data, you can email us at <span style={{color: "#003f9c", cursor: "pointer"}} onClick={() => window.location.href = 'mailto:info@evercomm.com.sg'}>info@evercomm.com.sg</span>. Alternatively, if you are located in the European Union, you can also have recourse to the European Data Protection Supervisor or with your nation’s data protection authority.</p>
                <h className="texts fw-bold" style={{ fontSize: 20 }}>10. Queries Concerning Your Personal Data</h>

                <p className="texts" style={{ fontSize: 20 }}>If you have any queries, comments or instructions relating to your personal data or this Privacy Policy, please direct them to our Data Protection Officer at:</p>
                <p className="texts" style={{ fontSize: 20 }}>Evercomm Singapore Pte. Ltd.</p>
                <p className="texts" style={{ fontSize: 20 }}>Attn: Data Protection Officer</p>
                <p className="texts m-0" style={{ fontSize: 20 }}>71 Nanyang Drive</p>
                <p className="texts m-0" style={{ fontSize: 20 }}>#2M-03 Innovation Centre</p>
                <p className="texts" style={{ fontSize: 20 }}>Singapore 638075</p>
                <p className="texts m-0" style={{ fontSize: 20 }}>Email: <span style={{color: "#003f9c", cursor: "pointer"}} onClick={() => window.location.href = 'mailto:info@evercomm.com.sg'}>info@evercomm.com.sg</span></p>
                <p className="texts" style={{ fontSize: 20 }}>Contact: +65 6871 8807</p>
            </div>
        </div >
    )
}
export default Privacy;